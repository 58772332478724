import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth';
import orders from './modules/orders';
import order from './modules/order';
import assets from './modules/assets';
import userinterface from './modules/userinterface';

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    auth,
    orders,
    order,
    assets,
    userinterface
  }
});
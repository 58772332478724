import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    items: [],
    features: {
      duplicate: true
    }
  }),
  actions: {
    load({state, rootState}, payload) {
      return new Promise((resolve, reject) => {


        var filters = [
          {
            property: 'created_by',
            operator: '=',
            value: rootState.auth.user.id
          }
        ];
        if(rootState.auth.user.is_admin){
          filters = [];
        }

        if(typeof payload == 'undefined' || typeof payload.archived == 'undefined'){
          filters.push({
            property: 'archived_by',
            operator: 'notexists'
          })
        }
        axios.get('api/orders?filter=' + JSON.stringify(filters))
          .then((response) => {
            state.items.splice(0);
            response.data.orders.forEach((item) => {
              state.items.push(item);
            })
            resolve(response.data.orders);
          })
          .catch((error) => {
            reject(error);
          })
      })
    }
  }
}
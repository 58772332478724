import Vue from 'vue'
import router from './router'
import store from './store'
import axios from "axios";
import messages from './lang';
import VueDayjs from 'vue-dayjs-plugin'
import VueI18n from 'vue-i18n';
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueI18n);

window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const i18n = new VueI18n({
  locale: 'de',
  silentTranslationWarn: true,
  fallbackLocale: 'de',
  messages
});

Vue.use(VueDayjs, {
  lang: i18n.locale
});


new Vue({
  router,
  i18n,
  axios,
  VueDayjs,
  store,
  render: h => h(App)
}).$mount('#app')
<template>
  <div class="app-frame">
      <Alert></Alert>
    <router-view />
  </div>
</template>
<script>
import axios from "axios";
import Alert from "@/components/alert/Alert.vue";

export default {
  components: {Alert},
  data: function () {
    return {
      allowed_unauthenticated_routes: ['login','register','setpassword','forgotpassword','resetpassword'],
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$router.onReady(() => {
        this.autologin();
      });
      this.keepaliveInterval = setInterval(() => {
        this.autologin();
      }, 3600000);
    },
    autologin: function () {
      return new Promise((resolve, reject) => {
        if (localStorage.getItem('user') !== null && localStorage.getItem('token') !== null) {
          window.axios.defaults.headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'UID': localStorage.getItem('user')
          }

          axios.get('front/user/keepalive')
              .then((response) => {
                this.$store.dispatch('auth/useLogin', response.data);
                this.$store.dispatch('assets/load', {refresh: true});
                resolve(response);
              })
              .catch(() => {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                window.axios.defaults.headers = {};
                reject();
                this.$router.push('/');
              });
        } else {
          if (!this.allowed_unauthenticated_routes.includes(this.$route.name)) {
            this.$router.push('/');
            reject();
          }
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import '@/sass/app.scss';
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/landing/Index'),
    children: [
      {
        path: '/',
        name: 'login',
        component: () => import('../views/auth/Login')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/auth/Register')
      },
      {
        path: '/set-password/:key',
        name: 'setpassword',
        component: () => import('../views/auth/SetPassword')
      },
      {
        path: '/forgot-password',
        name: 'forgotpassword',
        component: () => import('../views/auth/ForgotPassword')
      },
      {
        path: '/reset-password/:key',
        name: 'resetpassword',
        component: () => import('../views/auth/ResetPassword')
      },
    ]
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/orders/Index'),
    children: [
      {
        path: '/',
        name: 'listing',
        component: () => import('../views/orders/Listing')
      },
      {
        path: ':id',
        name: 'order',
        component: () => import('../views/orders/Edit')
      }
    ]
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    data: {
      alert: {
        active: false,
        message: '',
        callback: null,
        icon: 'warning'
      }
    }
  }),
  actions: {
    showAlert({state}, payload) {
      state.data.alert.message = payload.message;
      state.data.alert.messagedata = payload.messagedata;
      state.data.alert.callback = payload.callback;
      state.data.alert.icon = payload.icon;
      state.data.alert.active = true;
    },
    hideAlert({state}) {
      state.data.alert.active = false;
    }
  }
}
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    assets: []
  }),
  mutations: {},
  actions: {
    load({state}, payload) {
      return new Promise((resolve, reject) => {
        var params = '';
        if (payload.refresh === true) {
          params += 'refresh=yes'
        }

        var filters = [
          { property: 'active',
          opertator: '=',
          value: true
          }
        ];
        axios.get('api/assets?' + params + '&filter=' + JSON.stringify(filters))
          .then((response) => {
            state.assets.splice(0);
            response.data.assets.forEach((item) => {
              state.assets.push(item);
            });
            resolve(response.data.assets);
          })
          .catch((error) => {
            reject(error);
          })
      })
    }
  },
  getters: {
    index(state) {
      return state.assets;
    }
  }
}
<template>
    <div class="alert-container" v-bind:class="{active: userinterface.data.alert.active === true }">
        <div class="alert-dialog">
            <div class="icon-message-container">
                <div class="alert-icon">
                    <div
                            class="material-icons"
                    >
                        {{ userinterface.data.alert.icon }}
                    </div>
                </div>
                <div class="alert-message">
                    {{ $t(userinterface.data.alert.message, messagedata(userinterface.data.alert.messagedata)) }}
                </div>
            </div>
            <div class="alert-action" @click="callback()">
                <button class="alert-action-button default">{{ $t('ok') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data: function () {
    return {}
  },
  methods: {
    callback() {
      if (this.userinterface.data.alert.callback !== null) {
        this.userinterface.data.alert.callback();
      }
      this.$store.dispatch('userinterface/hideAlert');
    },
    messagedata(messagedata){
      var output = {}
      if(typeof messagedata === 'undefined' || typeof messagedata !== 'object'){
        return output;
      }
      Object.keys(messagedata).forEach((item) => {
        output[item] = this.$t(messagedata[item]);
      })
      return output;
    }
  },
  computed: {
    ...mapState({
      userinterface: 'userinterface'
    })
  }
}

</script>
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    languages: ['de', 'fr'],
    user: {
      id: null,
      email: ''
    },
    token: ""
  }),
  mutations: {
    login(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
    },
    logout(state, payload) {
      state.user = payload.user;
      state.token = "";
    }
  },
  actions: {
    useLogin({commit}, payload) {
      commit('login', {
        user: payload.user,
        token: payload.token
      });
    },
    loginAsync({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('front/user/login', {
          email: payload.email,
          password: payload.password,
        }).then((response) => {

          window.axios.defaults.headers = {'Authorization': 'Bearer ' + response.data.token, 'UID': response.data.user.id};

          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', response.data.user.id);

          commit('login', {
            user: response.data.user,
            token: response.data.token
          });

          resolve(response.data.user);

        }).catch((error) => {
          reject(error.response);
        });
      })
    },
    logoutAsync({commit}) {
      return new Promise((resolve) => {
        // Remove local storage token
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        // Remove authorization Bearer token header
        window.axios.defaults.headers.common = {};

        // Commit to default user, license and token store
        commit('logout', {
          user: {
            id: null,
            username: '',
            email: '',
            first_name: '',
            last_name: '',
            language: '',
            properties: []
          },
          roles: {},
          token: "",
        });
        resolve();
      });
    },
    checkPasswordStrength(_, payload) {
      return new Promise((resolve) => {
      axios.post('/password-strength', {password: payload.password, username: '', email: ''})
        .then((response) => {
          resolve(response.data.score);
        });
      });
    },
    setPassword(_, payload) {
      return new Promise((resolve) => {
        axios.post('/front/user/set-password', {
          token: payload.token,
          password: payload.password,
          password_repeat: payload.password_repeat
        })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    forgotPassword(_, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/front/user/forgot-password', {email: payload.email})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    resetPassword(_, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/front/user/reset-password', {
          token: payload.token,
          password: payload.password,
          password_repeat: payload.password_repeat
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            alert(this.$t('login failed'))
            reject(error);
          });
      });
    },
    register(_, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/front/user/register', {
          email: payload.email,
          firstname: payload.firstname,
          lastname: payload.lastname,
          locale: payload.locale
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          })
      })
    }
  },
  getters: {
    loggedIn: (state) => {
      return state.user.id !== null && state.token !== ""
    },
	readonly: (state) => {
		return typeof state.user.readonly !== 'undefined' && state.user.readonly === true;
	}
  }
}
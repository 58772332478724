import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    data: {
      id: null,
      loaded: false
    },
    units: [
      {value: 'g'},
      {value: 'kg'},
      {value: 'l'},
      {value: 'dl'},
      {value: 'cl'},
      {value: 'ml'},
      {value: 'pcs'}
    ],
    addresses: [
      {name: 'aare'},
      {name: 'basel'},
      {name: 'luzern'},
      {name: 'ostschweiz'},
      {name: 'zurich'},
      {name: 'vaud'},
      {name: 'geneve'},
      {name: 'neuchatel'},
      {name: 'wallis'},
      {name: 'bundzurich'},
    ],
    mCheckSupplierInfo: "https://suppliernet.migros.net/irj/portal?NavigationTarget=navurl://d88d51ac787d1c193cdf811f56255e9f&language=de",
    nutritionalValuesOptional: [
      {
        value: 'vitamin d',
        model: 'vitamin_d',
        unit: 'ug'
      },
      {
        value: 'vitamin e',
        model: 'vitamin_e',
        unit: 'mg'
      },
      {
        value: 'vitamin c',
        model: 'vitamin_c',
        unit: 'ug'
      },
      {
        value: 'vitamin b1',
        model: 'vitamin_b1',
        unit: 'mg'
      },
      {
        value: 'folic acid',
        model: 'folic_acid',
        unit: 'ug'
      },
      {
        value: 'calcium',
        model: 'calcium',
        unit: 'mg'
      },
    ],
    languages: [
      {
        value: 'de',
        title: 'language_de',
        choices: ['de']
      },
      {
        value: 'fr',
        title: 'language_fr',
        choices: ['fr']
      },
      {
        value: 'de_fr',
        title: 'language_de_fr',
        choices: ['de', 'fr']
      },
      {
        value: 'fr_de',
        title: 'language_fr_de',
        choices: ['fr', 'de']
      },
    ],
    datingNotices: [
      {
        name: 'mindestens haltbar bis',
        id: 'packaging/back/dating_notice/mindestens_haltbar_bis.svg'
      },
      {
        name: 'mindestens haltbar bis ende',
        id: 'packaging/back/dating_notice/mindestens_haltbar_bis_ende.svg'
      },
      {
        name: 'zu verbrauchen bis',
        id: 'packaging/back/dating_notice/verbrauchen_bis.svg'
      },
      {
        name: 'abgepackt am',
        id: 'packaging/back/dating_notice/abgepackt_am.svg'
      },
      {
        name: 'abgepackt am zu verbrauchen bis',
        id: 'packaging/back/dating_notice/abgepackt_am_zu_verbrauchen_bis.svg'
      },
      {
        name: 'gelegt ab zu verkaufen bis haltbar bis',
        id: 'packaging/back/dating_notice/gelegt_ab_verkaufen_bis_haltbar_bis.svg'
      }
    ],
    coolingNotices: [
      {
        name: 'cooling 0 - 8',
        id: 'packaging/back/cooling/cooling_0-8.svg'
      },
      {
        name: 'cooling 5 max',
        id: 'packaging/back/cooling/cooling_5_max.svg'
      },
      {
        name: 'cooling 2 max',
        id: 'packaging/back/cooling/cooling_2_max.svg'
      },
      {
        name: 'cooling 4 - 8',
        id: 'packaging/back/cooling/cooling_4-8.svg'
      },
      {
        name: 'cooling minus 18 max',
        id: 'packaging/back/cooling/cooling_-18max.svg'
      },
      {
        name: 'cooling less 20',
        id: 'packaging/back/cooling/cooling_20.svg'
      }
    ],
    storageNotices: [
      {
        name: 'without storage',
      }
    ],
    additionalStorageNotices: [
      {
        id: 'packaging/back/additional_storage/ohne_kuehlung.svg',
        name: 'without cooling'
      },
      {
        id: 'packaging/back/additional_storage/vor_waerme_schuetzen.svg',
        name: 'protect from heat'
      },
      {
        id: 'packaging/back/additional_storage/vor_waerme_geschuetzt_trocken_geruchfrei.svg',
        name: 'protect from head dry scentfree'
      },
      {
        id: 'packaging/back/additional_storage/nach_oeffnen_max_5.svg',
        name: 'after opening max 5'
      },
      {
        id: 'packaging/back/additional_storage/vor_licht_geschuetzt_max_5.svg',
        name: 'shielded from light max 5'
      }
      /*,
      May be used elsewhere. Duplicate in manual on page 9
      {
        id: 'packaging/back/additional_storage/unter_schutzatmosphaere_verpackt.svg',
        name: 'packaged under protective atmosphere'
      }
      */

    ],
    recyclingNotices: [
      {
        id: 'packaging/back/recycling/kehrichtabfuhr.svg',
        name: 'waste'
      },
      {
        id: 'packaging/back/recycling/karton-recycling.svg',
        name: 'cardboard recycling'
      },
      {
        id: 'packaging/back/recycling/papier-recycling.svg',
        name: 'paper recycling'
      },
      {
        id: 'packaging/back/recycling/glas.svg',
        name: 'glass'
      },
      {
        id: 'packaging/back/recycling/pet-recycling.svg',
        name: 'pet recycling'
      },
      {
        id: 'packaging/back/recycling/plastikflaschen-recycling.svg',
        name: 'plastic bottle recycling'
      }, {

        id: 'packaging/back/recycling/stahlblech-recycling.svg',
        name: 'steel recycling'
      },
      {
        id: 'packaging/back/recycling/aluminium-recycling.svg',
        name: 'aluminum recycling'
      },
      {
        id: 'packaging/back/recycling/take-away.svg',
        name: 'take away'
      },
      {
        id: 'packaging/back/recycling/kompostierbar.svg',
        name: 'compostable'
      },
    ],
    labels: [
      {name: 'vegan'},
      {name: 'grta'},
      {name: 'vegetarian'},
      {name: 'biosuisse'},
      {name: 'glutenfree'},
      {name: 'aha'},
      {name: 'aop'},
      {name: 'ipsuisse'},
      {name: 'igp'},

    ],
    ahaAllergies: [
      {name: 'glutenfree'},
      {name: 'glutenfree with oats'},
      {name: 'without wheat'},
      {name: 'lactosefree'},
      {name: 'without milk'},
      {name: 'without egg'},
      {name: 'without celery'},
      {name: 'without soy'},
      {name: 'without nuts'},
      {name: 'without mustard'},
    ],
    saving: false,
    currentStep: 0,
    steps: [
      {
        title: 'customer order information',
        id: 0,
        component: 'BestellerInformationen',
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'distribution address',
        id: 1,
        component: 'VertriebsadresseProduzent',
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'article',
        id: 2,
        component: "ArtikelInfos",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'product definition',
        id: 3,
        component: "ProduktDefinition",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'definition front of pack',
        id: 4,
        component: "Vorderseite",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'definitions ingredients amounts',
        id: 5,
        component: "Ingredienzen",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'nutritional values',
        id: 6,
        component: "Naehrwerte",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'hints',
        id: 7,
        component: "Hinweise",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'labels certifications',
        id: 8,
        component: "LabelZertifizierung",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'visual front main',
        id: 9,
        component: "Layout",
        relevance: {
          joghurt: false
        }
      },
      {
        title: 'landscape picture',
        id: 10,
        component: "Landschaftsbild",
        relevance: {
          joghurt: false
        }
      },
      {
        title: 'visual front',
        id: 11,
        component: "VisualFront",
        relevance: {
          joghurt: false
        }
      },
      {
        title: 'visual illustration',
        id: 12,
        component: "VisualProduktabbildung",
        relevance: {
          joghurt: false
        }
      },
      {
        title: 'visual illustration front',
        id: 13,
        component: "VisualVorderseiteJoghurt",
        relevance: {
          joghurt: true
        }
      },
      {
        title: 'summary',
        id: 14,
        component: "Summary",
        relevance: {
          joghurt: null
        }
      },
      {
        title: 'checkout',
        id: 15,
        component: "Checkout",
        relevance: {
          joghurt: null
        }
      }

    ],
    showValidations: false,
    pageValidations:
      [
        {
          /* BestellerInformationen */
          id: 0,
          requiredFields: [
            'order address',
            'billing address',
            'shipping address'
          ]
        }, {
        /* VertriebsadresseProduzent */
        id: 1,
        requiredFields: [
          'distributer address'
        ]
      }, {
        /* ArtikelInfos */
        id: 2,
        requiredFields: [
          'producer',
          'dekla pdf',
          'article number or definition',
          'article ean'
        ]
      }, {
        /* ProduktDefinition */
        id: 3,
        requiredFields: [
          'product category',
          'product type',
          'packaging type',
          'stanzriss'
        ]
      }, {
        /* Vorderseite */
        id: 4,
        requiredFields: [
          'product title',
          'packshot_current_product'
        ]
      }, {
        /* Ingredienzen */
        id: 5,
        requiredFields: [
          'technical description',
          'allergy description',
          'container capacity',
          'nutriscore',
          'ingredients'
        ]
      }, {
        /* Naehrwerte */
        id: 6,
        requiredFields: [
          'nutritional values'
        ]
      }, {
        /* Hinweise */
        id: 7,
        requiredFields: [
          'dating notice',
          'cooling notice',
          'storage notice',
          'recycling notice'

        ]
      }, {
        /* LabelZertifizierung */
        id: 8,
        requiredFields: [
          'labels',
          'custom label',
          'aha allergies',
          'labels ipsuisse',
          'labels biosuisse',
          'mcheck',
          'mcheck animalsafe rating',
          'mcheck climate rating',
          'mcheck packaging rating',
          'mcheck environmentfriendly rating',
          'mcheck sensiblefishing rating',
          'mcheck recycable rating',
        ]
      }, {
        /* Landschaftsbild */
        id: 10,
        requiredFields: [
          'landscape illustration',
          'landscape illustration order'
        ]
      }, {
        /* VisualFront */
        id: 11,
        requiredFields: [
          'depiction',
          'custom depiction'
        ]
      }, {
        /* VisualProduktabbildung */
        id: 12,
        requiredFields: [
          'product depiction',
          'custom product depiction'
        ]
      }, {
        /* VisualVorderseiteJoghurt */
        id: 13,
        requiredFields: [
          'joghurt type',
          'joghurt custom type'
        ]
      }
      ],
    baseSettings: {
      "id": null,
      "addresses": {
        "order_address": {
          "company": "",
          "name": "",
          "street": "",
          "pob": "",
          "zip": "",
          "city": "",
          "email": "",
          "phone": "",
          "psp_number": ""
        },
        "billing_address": {
          "differs": false,
          "company": "",
          "name": "",
          "street": "",
          "pob": "",
          "zip": "",
          "city": "",
          "email": "",
          "psp_number": ""
        },
        "proof_shipping_address": {
          "differs": false,
          "company": "",
          "name": "",
          "street": "",
          "pob": "",
          "zip": "",
          "city": "",
          "email": "",
          "psp_number": ""
        },
        "distributer_address": ""
      },
      "product": {
        "producer": {
          "company": "",
          "zip": "",
          "city": ""
        },
        "supplier": {
          "company": "",
          "zip": "",
          "city": ""
        },
        "article_number": ['','','',''],
        "article_definition": "",
        "material_number": "",
        "ean": "",
        "definition": {
          "product_category": "",
          "product_type": "",
          "packaging_type": ""
        }
      },
      "packaging": {
        "general": {
          "container": "",
          "material": "",
          "lid": "",
          "punching_cracks": null
        },
        "front": {
          "language": "de",
          "title": {
            "de": "",
            "fr": "",
            "it": "",
            "en": ""
          },
          "subtitle": {
            "de": "",
            "fr": "",
            "it": "",
            "en": ""
          },
          "nutriscore": false,
          "nutriscore_rating": "",
          "layout": {
            "type": "novisual",
            "landscape": null,
            "custom_landscape": {
              "required": false,
              "description": ""
            },
            "depiction": {
              "required": false,
              "image": null,
              "description": ""
            },
            "product_depiction": {
              "required": true,
              "image": null,
              "description": ""
            },
            "custom_product_depiction": {
              "required": false,
              "image": null,
              "description": ""
            },
            "joghurt": {
              "required": false,
              "image": null,
              "description": ""
            }
          }
        },
        "back": {
          "technical_description": {
            "de": "",
            "fr": "",
            "it": "",
            "en": ""
          },
          "ingredients": {
            "de": "",
            "fr": "",
            "it": "",
            "en": ""
          },
          "allergy_description": {
            "de": "",
            "fr": "",
            "it": "",
            "en": ""
          },
          "container_capacity": {
            "amount": "",
            "unit": null
          },
          "nutritional_values": {
            "energy": {
              "kj": 0,
              "kcal": 0
            },
            "fat": 0,
            "saturated_fats": 0,
            "carbohydrates": 0,
            "sugar": 0,
            "fiber": 0,
            "protein": 0,
            "salt": 0,
            "vitamin_d": {
              "percent": 0,
              "ug": 0
            },
            "vitamin_e": {
              "percent": 0,
              "mg": 0
            },
            "vitamin_c": {
              "percent": 0,
              "ug": 0
            },
            "vitamin_b1": {
              "percent": 0,
              "mg": 0
            },
            "folic_acid": {
              "percent": 0,
              "ug": 0
            },
            "calcium": {
              "percent": 0,
              "mg": 0
            },
          },
          "additional": {
            "dating_notice": null,
            "cooling": {
              "required": false,
              "icon": null
            },
            "storage": {
              "required": false,
              "max_temperature": "",
              "icon": null,
              "additional_storage_notice": ""
            },
            "recycling": [],
            "labels": [],
            "custom_label": {
              "description": ""
            },
            "aha_allergies": [],
            "certifications": {
              "bio": {
                "de": "",
                "fr": "",
                "it": "",
                "en": ""
              },
              "ipsuisse": {
                "de": "",
                "fr": "",
                "it": "",
                "en": ""
              }
            },
            "m_check": {
              "required": false,
              "checks": {
                "climate": {
                  "checked": false,
                  "rating": null
                },
                "animalsafe": {
                  "checked": false,
                  "rating": null
                },
                "packaging": {
                  "checked": false,
                  "rating": null
                },
                "recycable": {
                  "checked": false,
                  "rating": null
                },
                "environmentfriendly": {
                  "checked": false,
                  "rating": null
                },
                "sensiblefishing": {
                  "checked": false,
                  "rating": null
                }
              }
            }
          }
        }
      },
      "documents": {},
      "uploads": [],
      "pdf_contents": {
        "article_number": false,
        "material_number": false,
        "ean": false,
        "nutriscore": false,
        "technical_description": false,
        "allergy_description": false,
        "ingredients": false,
        "container_capacity": false,
        "dating_notice": false,
        "cooling_notice": false,
        "storage_notice": false,
        "packshot_current_product": false
      },
      "comments": "",
      /*"created_on": null,
      "updated_on": null,*/
      "submitted_on": null,
      "sent_to": []
    }
  }),
  mutations: {
    reset(state) {
      state.data = JSON.parse(JSON.stringify(state.baseSettings));
    },
    step(state, payload) {

      // Payload defines step
      if (typeof payload !== 'undefined') {
        state.currentStep = payload;
        localStorage.setItem('order.' + state.data.id + '.step', payload);
        return;
      }

      // Local storage defines step
      if (typeof localStorage.getItem('order.' + state.data.id + '.step') != 'undefined' && localStorage.getItem('order.' + state.data.id + '.step') !== null) {
        state.currentStep = parseInt(localStorage.getItem('order.' + state.data.id + '.step'));
        return;
      }

      localStorage.setItem('order.' + state.data.id + '.step', 0);
      state.currentStep = 0;
    }
  },
  actions: {
    resetOrder(_, payload) {
      return new Promise((resolve) => {
        axios.get('api/orders/' + payload.id)
          .then((response) => {
            var order = response.data;
            order.submitted_on = null;
            order.sent_to = null;
            axios.patch('api/orders/' + payload.id, order)
              .then((response) => {
                resolve(response);
              })
          })
      })
    },
    archiveOrder(_, payload) {
      return new Promise((resolve) => {
        axios.patch('api/orders/' + payload.id + '/archive')
          .then((response) => {
            resolve(response);
          })
      })
    },
    restoreOrder(_, payload) {
      return new Promise((resolve) => {
        axios.patch('api/orders/' + payload.id + '/restore')
          .then((response) => {
            resolve(response);
          })
      })
    },
    updateDocuments({state}) {
      return new Promise((resolve, reject) => {
        axios.get('api/orders/' + state.data.id).then((response) => {
          state.data.documents = response.data.documents;
          resolve();
        })
          .catch((error) => {
            reject(error);
          })
      });
    },
    load({state, commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('api/orders/' + payload.id)
          .then((response) => {

            state.data = {...JSON.parse(JSON.stringify(state.baseSettings)), ...response.data};

            /* Upgrade opened order if required */
            if (state.data.product.definition.product_category === null) {
              state.data.product.definition.product_category = '';
            }


            if(typeof state.data.packaging.back.additional.m_check.checks.environmentfriendly === 'undefined'){
              state.data.packaging.back.additional.m_check.checks.environmentfriendly = {
                "checked": false,
                "rating": null
              }
            }

            if(typeof state.data.packaging.back.additional.m_check.checks.recycable === 'undefined'){
              state.data.packaging.back.additional.m_check.checks.recycable = {
                "checked": false,
                "rating": null
              }
            }
            if(typeof state.data.packaging.back.additional.m_check.checks.sensiblefishing === 'undefined'){
              state.data.packaging.back.additional.m_check.checks.sensiblefishing = {
                "checked": false,
                "rating": null
              }
            }
            /* Map product categories */
            var mappings = {
              'candies' : 'food-candies_snacks_baking-candies',
              'biscuits' : 'food-candies_snacks_baking-biscuits',
              'breakfast' : 'food-frozen_beverages_breakfast-breakfast',
              'drinks' : 'food-frozen_beverages_breakfast_frozen_beverages',
              'sides' : 'food-sides_cans_pets-sides',
              'canned' : 'food-sides_cans_pets-cans',
              'finebakery' : 'fresh-convenience_bread_pastry-pastry',
              'eggs' : 'fresh-milkproducts_cheese_eggs-eggs',
              'joghurt' : 'fresh-milkproducts_cheese_eggs-dairy_milkreplacement-joghurt',
            }

            var alertMappings = {
              'apero' : '',
              'convenience' : '',
              'milk' : '',
              'cheese' : '',
              'meatfish' : '',
              'vegetablefruits' : '',
            }
            var legacyCategory = JSON.parse(JSON.stringify(state.data.product.definition.product_category));

            if(Object.keys(alertMappings).includes(state.data.product.definition.product_category)){

              state.data.product.definition.product_category = '';

              dispatch('userinterface/showAlert', { callback: function() {
                state.showValidations = true;
                state.currentStep=3;
                },
                message: 'category changed please remap',
                messagedata: { category: 'product_categories.' + legacyCategory },
                icon: 'warning'
              }, { root: true})

            }
            if(Object.keys(mappings).includes(state.data.product.definition.product_category)){

              state.data.product.definition.product_category = mappings[state.data.product.definition.product_category];

              dispatch('userinterface/showAlert', { callback: function() {
                  state.currentStep=3;
                },
                message: 'category remapped',
                messagedata: { category: 'product_categories.' + legacyCategory },
                icon: 'warning'
              }, { root: true})
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    delete(_, payload) {
      return new Promise((resolve, reject) => {
        axios.delete('api/orders/' + payload.id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      });
    },
    duplicate(_, payload) {
      return new Promise((resolve, reject) => {
        var order;

        axios.post('api/orders/duplications', {id: payload.id})
          .then((response) => {
            order = response.data;
            order.submitted_on = null;
            order.sent_to = null;

            axios.patch('api/orders/' + order.id, order).then((response) => {
              resolve(response.data);
            }).catch(() => {
              reject();
            })
          }).catch(() => {
          reject();
        });
      })
    },
    create({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        /* Reset order to default values and create a new order */
        commit('reset');
        commit('step', 0);
        axios.post('api/orders', {created: true})
          .then((response) => {
            state.data.id = response.data.id;
            resolve(response.data);
            dispatch('orders/load', null, {root: true});
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    submit({state, dispatch}) {
      return new Promise((resolve, reject) => {
        state.data.submitted_on = new Date();
        dispatch('update').then(() => {

          resolve();

          var params = {
            recipients: [
              'wchoong@detail.ch'
            ]
          };

          axios.post('api/orders/' + state.data.id + '/submit', params)
            .then((response) => {
              state.data.submitted_on = response.data.submitted_on;
              state.data.sent_to = response.data.sent_to;
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        })
      });
    },
    update({state}) {
      return new Promise((resolve, reject) => {
          state.saving = true;
          axios.patch('api/orders/' + state.data.id, state.data)
            .then((response) => {
              state.order = response.data;
              resolve();
              setTimeout(() => {
                state.saving = false;
              }, 1000);
            })
            .catch((error) => {
              reject(error);
            });
        }
      )
    }

  },
  getters: {
    isSaving(state) {
      return state.saving;
    },
    languages(state) {
      return state.languages;
    },
    languageChoices(state) {
      var language = state.languages.find((item) => {
        if (item.value === state.data.packaging.front.language) {
          return item;
        }
      });

      return language.choices;
    },
    nextStep(state, getters) {
      var next = Math.min(state.steps.length - 1, state.currentStep + 1);

      while(!getters['availableSteps'].includes(next)){
        next++;
      }
      if(!getters['availableSteps'].includes(next)){
        next = state.currentStep;
      }
      if(next > state.steps.length - 1){
        next = state.steps.length - 1;
      }
      return next;
    },
    previousStep(state, getters) {
      var previous = Math.max(0, state.currentStep - 1);

      while(!getters['availableSteps'].includes(previous)){
        previous--;
      }
      if(!getters['availableSteps'].includes(previous)){
        previous = state.currentStep;
      }
      if(previous < 0){
        previous = 0;
      }

      return previous;
    },
    stepInformation(state) {
      var step = state.steps.find((item) => {
        if (item.id === state.currentStep) {
          return item;
        }
      });
      return step;
    },
    validationErrors(state, getters) {
      var errors = [];
      if (state.data.loaded === false) {
        return true;
      }


      /* 1 Supplier Contact Information */

      if (
        !state.data.addresses.order_address.company ||
        !state.data.addresses.order_address.name ||
        !state.data.addresses.order_address.street ||
        !state.data.addresses.order_address.zip ||
        !state.data.addresses.order_address.city ||
        !state.data.addresses.order_address.email ||
        !state.data.addresses.order_address.phone
      ) {
        errors.push('order address');
      }

      if (
        state.data.addresses.billing_address.differs === true && (
          !state.data.addresses.billing_address.company ||
          !state.data.addresses.billing_address.name ||
          !state.data.addresses.billing_address.street ||
          !state.data.addresses.billing_address.zip ||
          !state.data.addresses.billing_address.city ||
          !state.data.addresses.billing_address.email
        )
      ) {
        errors.push('billing address');
      }
      if (
        state.data.addresses.proof_shipping_address.differs === true && (
          !state.data.addresses.proof_shipping_address.company ||
          !state.data.addresses.proof_shipping_address.name ||
          !state.data.addresses.proof_shipping_address.street ||
          !state.data.addresses.proof_shipping_address.zip ||
          !state.data.addresses.proof_shipping_address.city
        )
      ) {
        errors.push('shipping address');
      }


      /* 2 Distributer Address */
      if (state.data.addresses.distributer_address === "") {
        errors.push('distributer address');
      }

      if (!state.data.pdf_contents.article_number) {

        if(!state.data.product.article_number || typeof state.data.product.article_number !== 'object'){
          state.data.product.article_number = ['','','',''];
          errors.push('article number or definition');

        } else {
          var parts = [
            (state.data.product.article_number[0] && /^\d+$/.test(state.data.product.article_number[0])) ? state.data.product.article_number[0].length : -1,
            (state.data.product.article_number[1] && /^\d+$/.test(state.data.product.article_number[1])) ? state.data.product.article_number[1].length : -1,
            (state.data.product.article_number[2] && /^\d+$/.test(state.data.product.article_number[2])) ? state.data.product.article_number[2].length : -1,
            (state.data.product.article_number[3] && /^\d+$/.test(state.data.product.article_number[3])) ? state.data.product.article_number[3].length : -1
          ];
          if (
            (
              JSON.stringify(parts) !== JSON.stringify([4, 3, -1, -1]) &&
              JSON.stringify(parts) !== JSON.stringify([4, 3, 3, 2])
            ) ||
            !state.data.product.article_definition
          ) {
            errors.push('article number or definition');
          }
        }
      }


      if (!state.data.pdf_contents.ean &&
        (!state.data.product.ean ||
          state.data.product.ean.length !== 13
        || !state.data.product.ean.match(/^[0-9]+$/) )
      ) {
        errors.push('article ean');
      }

      /* 3 Product Definition */
      if (!state.data.product.definition.product_category || state.data.product.definition.product_category === '' || state.data.product.definition.product_category === null) {
        errors.push('product category');
      }

      if (!state.data.product.definition.product_type) {
        errors.push('product type');
      }

      if (!state.data.product.definition.packaging_type) {
        errors.push('packaging type');
      }

      var hasStanzriss = state.data.uploads.find((item) => {
        if (item.type === 'stanzriss') {
          return true;
        }
      });


      if(!hasStanzriss){
        errors.push('stanzriss');
      }

      if (!state.data.product.producer.company ||
        !state.data.product.producer.zip ||
        !state.data.product.producer.city
      ) {
        errors.push('producer');
      }

      /* Dekla Document */
      var pdfContents = Object.keys(state.data.pdf_contents);
      var hasDekla = state.data.uploads.find((item) => {
        if (item.type === 'dekla') {
          return true;
        }
      });
      var requiresDekla = false;
      pdfContents.forEach((item) => {
        if (state.data.pdf_contents[item] === true) {
          requiresDekla = true;
        }
      });

      if (requiresDekla && typeof hasDekla == 'undefined') {
        errors.push('dekla pdf');
      }


      /* 4 Packaging Front */
      if (!state.data.packaging.front.language) {
        errors.push('product language');
      }

      getters.languageChoices.forEach((item) => {
        if (!state.data.packaging.front.title[item] || state.data.packaging.front.title[item].length > 30) {
          errors.push('product title');
        }
      });

      var hasPackshotCurrentProduct = state.data.uploads.find((item) => {
        if (item.type === 'packshot_current_product') {
          return true;
        }
      });

      if(!hasPackshotCurrentProduct){
        errors.push('packshot_current_product');
      }

      /* 6 Ingredients */
      if (!state.data.pdf_contents.technical_description) {
        getters.languageChoices.forEach((item) => {
          if (!state.data.packaging.back.technical_description[item]) {
            errors.push('technical description');
          }
        });
      }

      if (!state.data.pdf_contents.ingredients) {
        getters.languageChoices.forEach((item) => {
          if (!state.data.packaging.back.ingredients[item]) {
            errors.push('ingredients');
          }
        });
      }


      if (!state.data.pdf_contents.allergy_description) {
        getters.languageChoices.forEach((item) => {
          if (!state.data.packaging.back.allergy_description[item]) {
            errors.push('allergy description');
          }
        });
      }

      if (!state.data.pdf_contents.container_capacity) {
        if (!state.data.packaging.back.container_capacity.amount || !state.data.packaging.back.container_capacity.unit) {
          errors.push('container capacity');
        }
      }
      if (!state.data.pdf_contents.nutriscore && !state.data.packaging.front.nutriscore_rating
      ) {
        errors.push('nutriscore');
      }



      /* 5 Nutritional Values */
      if (!state.data.pdf_contents.nutritional_values && (
        !state.data.packaging.back.nutritional_values.energy.kj ||
        !state.data.packaging.back.nutritional_values.energy.kcal ||
        !state.data.packaging.back.nutritional_values.fat ||
        !state.data.packaging.back.nutritional_values.saturated_fats ||
        !state.data.packaging.back.nutritional_values.carbohydrates ||
        !state.data.packaging.back.nutritional_values.sugar ||
        !state.data.packaging.back.nutritional_values.fiber ||
        !state.data.packaging.back.nutritional_values.protein ||
        !state.data.packaging.back.nutritional_values.salt
      )) {
        errors.push('nutritional values');
      }


      /* 6 Notices */
      if (!state.data.pdf_contents.dating_notice && !state.data.packaging.back.additional.dating_notice) {
        errors.push("dating notice");
      }

      if (
        !state.data.pdf_contents.cooling_notice &&
        state.data.packaging.back.additional.cooling.required &&
        !state.data.packaging.back.additional.cooling.icon
      ) {
        errors.push("cooling notice");
      }

      if (
        !state.data.pdf_contents.storage_notice &&
        state.data.packaging.back.additional.storage.required &&
        (
          !state.data.packaging.back.additional.storage.additional_storage_notice
        )
      ) {
        errors.push("storage notice");
      }

      if (!state.data.pdf_contents.storage_notice &&
        state.data.packaging.back.additional.storage.required &&
        state.data.packaging.back.additional.storage.additional_storage_notice === 'custom_max_temp' &&
        !state.data.packaging.back.additional.storage.max_temperature) {
        errors.push("storage notice");
      }

      if (state.data.packaging.back.additional.recycling.length === 0) {
        errors.push("recycling notice");
      }


      /* 7 Labels and Certifications */
      if (state.data.packaging.back.additional.labels.length > 2) {
        errors.push("labels");
      }

      if (state.data.packaging.back.additional.labels.includes('biosuisse')) {
        getters.languageChoices.forEach((item) => {
          if (!state.data.packaging.back.additional.certifications.bio[item]) {
            errors.push("labels biosuisse");
          }
        });
      }

      var hasCustomLabel = state.data.uploads.find((item) => {
        if (item.type === 'custom_label') {
          return true;
        }
      });

      if (state.data.packaging.back.additional.labels.includes('custom') &&
        (!state.data.packaging.back.additional.custom_label.description || !hasCustomLabel)
      ) {
        errors.push('custom label');
      }

      if (state.data.packaging.back.additional.labels.includes('aha')) {
        if (state.data.packaging.back.additional.aha_allergies.length === 0) {

          errors.push("aha allergies");
        }
      }
      if (state.data.packaging.back.additional.labels.includes('ipsuisse')) {
        getters.languageChoices.forEach((item) => {
          if (!state.data.packaging.back.additional.certifications.ipsuisse[item]) {
            errors.push("labels ipsuisse");
          }
        });
      }

      if (state.data.packaging.back.additional.m_check.required) {
        if (state.data.packaging.back.additional.m_check.checks.animalsafe.checked &&
          !state.data.packaging.back.additional.m_check.checks.animalsafe.rating) {
          errors.push("mcheck animalsafe rating")
        }

        if (state.data.packaging.back.additional.m_check.checks.climate.checked &&
          !state.data.packaging.back.additional.m_check.checks.climate.rating) {
          errors.push("mcheck climate rating")
        }

        if (state.data.packaging.back.additional.m_check.checks.packaging.checked &&
          !state.data.packaging.back.additional.m_check.checks.packaging.rating) {
          errors.push("mcheck packaging rating")
        }

        if (state.data.packaging.back.additional.m_check.checks.environmentfriendly.checked &&
          !state.data.packaging.back.additional.m_check.checks.environmentfriendly.rating) {
          errors.push("mcheck environmentfriendly rating")
        }

        if (state.data.packaging.back.additional.m_check.checks.sensiblefishing.checked &&
          !state.data.packaging.back.additional.m_check.checks.sensiblefishing.rating) {
          errors.push("mcheck sensiblefishing rating")
        }
        if (state.data.packaging.back.additional.m_check.checks.recycable.checked &&
          !state.data.packaging.back.additional.m_check.checks.recycable.rating) {
          errors.push("mcheck recycable rating")
        }

        if (
          !state.data.packaging.back.additional.m_check.checks.animalsafe.checked &&
          !state.data.packaging.back.additional.m_check.checks.climate.checked &&
          !state.data.packaging.back.additional.m_check.checks.packaging.checked &&
          !state.data.packaging.back.additional.m_check.checks.recycable.checked &&
          !state.data.packaging.back.additional.m_check.checks.environmentfriendly.checked &&
          !state.data.packaging.back.additional.m_check.checks.sensiblefishing.checked
        ) {
          errors.push("mcheck")
        }
      }

      /* 8 Layout */
      if (!state.data.packaging.front.layout.type) {
        errors.push("layout");
      }

      if(!state.data.product.definition.product_category.includes('joghurt')) {

        /* 8 Landschaftsbild */
        if (state.data.packaging.front.layout.custom_landscape.required === false &&
          state.data.packaging.front.layout.landscape === null) {
          errors.push('landscape illustration');
        }
        if (state.data.packaging.front.layout.custom_landscape.required === true) {
          var hasLandscape = state.data.uploads.find((item) => {
            if (item.type === 'landscape') {
              return true;
            }
          });
          if (!hasLandscape || !state.data.packaging.front.layout.custom_landscape.description) {
            errors.push('landscape illustration order');
          }
        }

        if(state.data.packaging.front.layout.type === 'withvisual') {
          /* 9 Vorderseite Visual */
          if (state.data.packaging.front.layout.depiction.required === false &&
            !state.data.packaging.front.layout.depiction.image
          ) {
            errors.push('depiction');
          }

          if (state.data.packaging.front.layout.depiction.required === true) {
            var hasDepiction = state.data.uploads.find((item) => {
              if (item.type === 'depiction') {
                return true;
              }
            });
            if (!hasDepiction || !state.data.packaging.front.layout.depiction.description) {
              errors.push('custom depiction');
            }
          }
        }
        if(state.data.packaging.front.layout.type === 'withproduct') {
          /* 10 Vorderseite Produktabbildung */
          if (state.data.packaging.front.layout.custom_product_depiction.required === false &&
            !state.data.packaging.front.layout.product_depiction.image) {
            errors.push('product depiction')
          }

          if (state.data.packaging.front.layout.custom_product_depiction.required === true) {
            var hasProductDepiction = state.data.uploads.find((item) => {
              if (item.type === 'productdepiction') {
                return true;
              }
            });
            if (!hasProductDepiction || !state.data.packaging.front.layout.custom_product_depiction.description) {
              errors.push('custom product depiction');
            }
          }
        }
      }
      if(state.data.product.definition.product_category.includes('joghurt')) {
        /* 11 Joghurt Visuals */
        if (!state.data.packaging.front.layout.joghurt.required &&
          !state.data.packaging.front.layout.joghurt.image) {
          errors.push('joghurt type');
        }

        if (state.data.packaging.front.layout.joghurt.required &&
          !state.data.packaging.front.layout.joghurt.description) {
          errors.push('joghurt custom type');
        }
      }

      return errors;
    },

    valid(state, getters) {
      return getters['validationErrors'].length === 0;
    },
    availableUnits(state) {
      return state.units;
    },
    availableNutritionalValuesOptional(state) {
      return state.nutritionalValuesOptional;
    },
    availableDatingNotices(state) {
      return state.datingNotices;
    },
    availableCoolingNotices(state) {
      return state.coolingNotices;
    },
    availableAdditionalStorageNotices(state) {
      return state.additionalStorageNotices;
    },
    availableRecyclingNotices(state) {
      return state.recyclingNotices;
    },
    availableLabels(state) {
      return state.labels;
    },
    availableAhaAllergies(state) {
      return state.ahaAllergies;
    },
    availableAddresses(state) {
      return state.addresses;
    },
    recyclingNotices(state) {
      var list = [];
      state.data.packaging.back.additional.recycling.forEach((item) => {
        var notice = state.recyclingNotices.find((rec) => {
          if (rec.id === item) {
            return rec;
          }
        });
        if (typeof notice !== 'undefined') {
          list.push(notice);
        }
      })
      return list;
    },
    availableSteps(state) {

      var stepList = [];

      for (var i = 0; i < state.steps.length; i++) {
        var step = state.steps.find((item) => {
          if (item.id === i) {
            return item;
          }
        });

        // Special rules apply for the product category Joghurt/Quark/Milchersatz, Remember that the indexes used are zero-based
        if(state.data.product.definition.product_category.includes('joghurt') && ([10,13].includes(i))){
          stepList.push(i);
          continue;
        }

        if(state.data.packaging.front.layout.type === 'novisual' && (i === 11 || i === 12)){
          continue;
        }

        if(state.data.packaging.front.layout.type === 'withvisual' && i === 12){
          continue;
        }

        if(state.data.packaging.front.layout.type === 'withproduct' && i === 11){
          continue;
        }


        if (step.relevance.joghurt === null) {
          stepList.push(i);
          continue;
        } else {
          if (state.data.product.definition.product_category.includes('joghurt') && step.relevance.joghurt === true) {

            stepList.push(i);
            continue;
          } else if (!state.data.product.definition.product_category.includes('joghurt') && step.relevance.joghurt === false) {

            stepList.push(i);
            continue;
          }
        }

      }
      return stepList;
    },
    pageValidations(state, getters) {
      var pages = [];
      if (typeof getters['validationErrors'] !== 'object') {
        return [];
      }
      state.steps.forEach((step) => {

        var pageValidation = state.pageValidations.find((item) => {
          if (item.id === step.id) {
            return item;
          }
        })
        if (typeof pageValidation !== 'undefined') {
          if (typeof pageValidation.requiredFields === 'object') {
            pageValidation.requiredFields.forEach((field) => {
              if (getters['validationErrors'].includes(field)) {
                if (getters['availableSteps'].includes(pageValidation.id)) {
                  pages.push(pageValidation.id);
                }
              }
            })
          }
        }


      });

      return pages;
    },
    requiresDekla(state){
      var requiresDekla = false;

      var pdfContents = Object.keys(state.data.pdf_contents);
      pdfContents.forEach((item) => {
        if (state.data.pdf_contents[item] === true) {
          requiresDekla = true;
        }
      });
      return requiresDekla;
    }
  }
}